




















































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import BaseTeamCard from '@improve/common-components/src/components/widgets/BaseTeamCard.vue';
import BaseAddButton from '@improve/common-components/src/components/widgets/BaseAddButton.vue';
import Team from '@improve/common-utils/src/model/Team';
import StatsData from '@improve/common-utils/src/model/StatsData';
import Ticket from '@improve/common-utils/src/model/Ticket';
import BaseTicketCard from '@/components/ticket/BaseTicketCard.vue';
import TicketSearchParams from '@improve/common-utils/src/types/TicketSearchParams';
import ImproveTicketStatus from '@improve/common-utils/src/types/ImproveTicketStatus';

@Component({
  name: 'SearchAllResults',
  components: {
    BaseTeamCard,
    BaseAddButton,
    BaseTicketCard
  }
})
export default class SearchAllResults extends Vue {
  @Getter teamStatsByID!: Map<string, StatsData>;

  @Getter foundTickets?: Array<Ticket>;

  @Getter foundTeams?: Array<Team>;

  @Action searchTickets!: ActionMethod;

  @Action searchTeams!: ActionMethod;

  @Action viewAllTickets!: ActionMethod;

  showOnlyTickets = false;

  showOnlyTeams = false;

  get teamDescription(): string {
    return this.foundTeams!.length === 1 ? 'team' : 'teams';
  }

  get ticketDescription(): string {
    return this.foundTickets!.length === 1 ? 'improve' : 'improves';
  }

  get viewAllDescription(): string {
    return this.$t('page.dashboard.viewAll').toString();
  }

  async created(): Promise<void> {
    if (this.$route.query.search) {
      if (this.$route.query.viewAllTickets) {
        this.showOnlyTickets = true;
        this.showOnlyTeams = false;
        await this.searchTickets(this.$route.query.search!.toString());
      }
      if (this.$route.query.viewAllTeams) {
        this.showOnlyTickets = false;
        this.showOnlyTeams = true;
        await this.searchTeams(this.$route.query.search!.toString());
      }
      if (this.$route.query.viewAllTicketsFromDash) {
        const searchBy = new TicketSearchParams();
        searchBy.status = this.$route.query.search! as Array<ImproveTicketStatus>;
        this.showOnlyTickets = true;
        this.showOnlyTeams = false;
        await this.viewAllTickets(searchBy);
      }
    }
  }

  fetchTeamStat(team: Team): StatsData {
    if (!this.teamStatsByID || !this.teamStatsByID.has(team.id!)) {
      return new StatsData();
    }
    return this.teamStatsByID.get(team.id!)!;
  }

  goToTeam(teamId: string): void {
    this.$router.push({
      name: 'TeamDetails',
      params: { id: teamId }
    });
  }
}
